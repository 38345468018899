(function (document) {
  'use strict';

  const params = new URLSearchParams(window.location.search);

  if (params.has('code') && params.has('state') && (window.location.href.indexOf('#!/branch-loading') <= -1)) {
    const {
    origin,
    pathname,
    search
    } = window.location;
    let url = origin + pathname + search;
    window.location.href = url + '#!/branch-loading';
  }

 
  if(window.location.href.endsWith('com.tr') || window.location.href.endsWith('com.tr/') || window.location.href.endsWith('dist/') || window.location.href.includes('CSOGENELSAKLAMA') || window.location.href.includes('CSOSAKLAMA')) {
    if( window.location.href.includes('CSOGENELSAKLAMA')){
      localStorage.setItem("page", ' #!/public-general');
      if(!window.location.href.includes('CSOGENELSAKLAMA')){
        window.location.href = window.location.href + `?lang=tr-TR&channelCode=CSOGENELSAKLAMA#!/public-general`;    
      }
    } else {
      localStorage.setItem("page", ' #!/');
      if(!window.location.href.includes('CSOSAKLAMA')){
         window.location.href = window.location.href + `?lang=tr-TR&channelCode=CSOSAKLAMA#!/`;
      }
    }
  }

  addEventListener('authentication-login-success', () => {
    //if(readCookie('login') === null) {}
    
    // The above if structure has been deleted to redirect to the login page after authomatic logout.
    const {origin , pathname, search} = window.location;
    let url = origin+pathname+search;
    window.location.href = url + '#!/branch-loading';
    window.location.reload();
    createCookie('LOGIN', true);
  })

  addEventListener('authentication-logout-success', () => {
    console.log('logout successfull in custody management app');
    createCookie('LOGOUT', true);
    eraseCookie('LOGIN');
    //test token refresh
    const channelCode = localStorage.getItem('page') == ' #!/public-general' ? 'CSOGENELSAKLAMA' : 'CSOSAKLAMA';
    const url = new URL(window.location.href);
    url.searchParams.set('channelCode', channelCode);
    window.history.replaceState(null, null, url);  
    const {origin , pathname, search} = window.location;
    window.location.href = origin+pathname+search + '#!/branch-loading';
    window.location.reload();
  })

  addEventListener('load', ()=>{
    eraseCookie('LOGOUT')
  })
 /* addEventListener('unload', ()=>{
    if(readCookie('LOGIN')){
         createCookie('LOGOUT', true);
         const rootPage = document.querySelector('branch-loading-page');
         const rootElement = rootPage.shadowRoot.querySelector('#authenticationManagerId');
         rootElement.requestLogout();
         const el = document.getElementById("cells-template-branch-loading")
         el.remove();   
    }
  })*/

  function createCookie(name, value, days) {
    var expires;

    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    } else {
        expires = "";
    }
    document.cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value) + expires + "; path=/";
}

function readCookie(name) {
    var nameEQ = encodeURIComponent(name) + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ')
            c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0)
            return decodeURIComponent(c.substring(nameEQ.length, c.length));
    }
    return null;
}

function eraseCookie(name) {
  createCookie(name,"",-1);
}
  window.CellsPolymer.start({
    routes: {
      'public-personal': '/',
      'public-general': '/public-general',
      'public-contact': '/public-contact',
      'public-security': '/public-security',
      'public-privacy': '/public-privacy',
      'branch-assets': '/branch-assets',
      'branch-accounts': '/branch-accounts',
      'public-temporary': '/public-temporary',
      'public-online-application':'/public-online-application',
      'public-online-application-ok':'/public-online-application-ok',
      'public-cookie-policy':'/public-cookie-policy',
      'branch-loading':'/branch-loading',
      'branch-customers':'/branch-customers',
      'branch-products':'/branch-products',
      'branch-investments':'/branch-investments',
      'branch-history-assets':'/branch-history-assets',
      'branch-customer-detail':'/branch-customer-detail',
      'branch-transactions':'/branch-transactions',      
      'branch-shipping-preference':'/branch-shipping-preference',
      'branch-change-password':'/branch-change-password',
      'branch-mobile-profile':'/branch-mobile-profile'
    },

  });

})(document);